// import Validator from '@/validators'
import i18n from '@/i18n'
import dayjs from 'dayjs'
const { t } = i18n.global

const moduleValidator = (rule, value, callback) => {
  if (value) {
    const len = value?.length || 0
    if (len % 8 !== 0) {
      return callback(new Error(i18n.global.t('validator.must-eight-multiple')))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

const isSelectDayAfterValidator = (rule, value, callback) => {
  if (value) {
    const currentTime = dayjs().format('YYMMDD')
    const selectTime = value
    if (selectTime <= currentTime) {
      callback(new Error(i18n.global.t('validator.early-or-equal')))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

export const baseUpdateFormConfig = {
  formItems: [
    {
      field: 'rid',
      type: 'input',
      label: 'param.rid',
      otherOptions: {
        disabled: true
      }
    },

    {
      field: 'index',
      type: 'input',
      label: 'general.index',
      otherOptions: {
        maxlength: 2
      }
    },

    {
      field: 'exponent',
      type: 'select',
      label: 'Exponent',
      options: [
        {
          label: '3',
          value: 3
        },
        {
          label: '5',
          value: 5
        },
        {
          label: '17',
          value: 17
        },
        {
          label: '257',
          value: 257
        },
        {
          label: '65537',
          value: '65537'
        }
      ]
    },

    {
      field: 'expDate',
      type: 'datepicker',
      label: 'Expiry Date',
      otherOptions: {
        format: 'YYMMDD',
        valueFormat: 'YYMMDD',
        type: 'date'
      }
    },
    {
      field: 'checkSum',
      type: 'input',
      label: 'Check Sum',
      otherOptions: {
        maxlength: 100
      }
    },
    {
      field: 'modul',
      type: 'textarea',
      label: 'Modulus',
      otherOptions: {
        showWordLimit: true,
        maxlength: 512
      },
      colLayout: {
        xl: 24,
        lg: 24,
        md: 24,
        sm: 24,
        xs: 24
      }
    },
    {
      field: 'description',
      type: 'textarea',
      label: 'general.description',
      colLayout: {
        xl: 24,
        lg: 24,
        md: 24,
        sm: 24,
        xs: 24
      },
      otherOptions: {
        maxlength: 500
      }
    }
  ],
  validateRules: {
    index: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      },
      {
        pattern: /^[0-9A-F]{2}$/,
        message: t('general.must-digits-letter'),
        trigger: 'change'
      }
    ],

    modul: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      },
      {
        pattern: /^[0-9A-F]+$/,
        message: 'Please input hexadecimal number',
        trigger: 'change'
      },
      { validator: moduleValidator, trigger: 'change' }
    ],
    exponent: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      }
    ],
    expDate: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      },
      {
        validator: isSelectDayAfterValidator,
        trigger: 'change'
      }
    ]
  }
}
