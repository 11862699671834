<template>
  <div class="base-form">
    <page-form
      ref="baseFormRef"
      v-bind="configOptions"
      @handleNewClick="handleSubmit"
      :pageInfo="pageInfo"
    >
      <!-- <template #customForm>
        <el-col :span="24" style="padding: 18px">
          <Ltable
            ref="parameterList"
            :listDataOptions="currentParameterData"
            :disabled="currentPageType === 'view'"
          >
          </Ltable>
        </el-col>
      </template> -->
    </page-form>
  </div>
</template>
<script setup>
import pageForm from '@/components/PageForm/page-form.vue'
import { baseNewFormConfig } from './config/new'
import { baseUpdateFormConfig } from './config/edit'
import { baseViewFormConfig } from './config/view'
// import Ltable from './dynamic-table2.vue'
import { ref, getCurrentInstance, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import i18n from '@/i18n'

const routes = useRoute()
const router = useRouter()
const { t } = i18n.global
// 1、预定义当前页面的map映射数组
const pageMapArray = [
  {
    // view routesName 跟你配置路由的name相关
    routesName: 'CapkView',
    baseFormConfig: baseViewFormConfig,
    pageTitle: t('general.router-parameter-capk-view'),
    pageType: 'view'
  },
  {
    routesName: 'CapkNew',
    baseFormConfig: baseNewFormConfig,
    pageTitle: t('general.router-parameter-capk-new'),
    pageType: 'add'
  },
  {
    routesName: 'CapkEdit',
    baseFormConfig: baseUpdateFormConfig,
    pageTitle: t('general.router-parameter-capk-edit'),
    pageType: 'edit',
    isResetFields: false
  }
]
// 2、根据当前路由对象中的name属性判断当前页面
const currentPageConfig = pageMapArray.find((item) => {
  if (item.routesName === routes.name) return item
})

const store = useStore()

// 3、将需要用到的属性解构出来
const { baseFormConfig, pageType } = currentPageConfig
// const currentPageType = ref(pageType)
const baseFormConfigRef = computed(() => {
  return baseFormConfig
})

// 4、区分当前页面是否需要转化options选项
const configOptions = ref({})

currentPageConfig.baseFormConfig = baseFormConfigRef
configOptions.value = currentPageConfig

const id = routes.params.id

const { appContext } = getCurrentInstance()
const pageInfo = ref({})
const handleMessageCommit =
  appContext.config.globalProperties.$handleMessageCommit
// const currentParameterData = ref([])
// 5、定义方法获取当前页面的初始化数据
const getCurrentPageInfo = async () => {
  const paramInfo = await store.dispatch('param/getCapkById', { id })
  paramInfo.tenantId = paramInfo.tenantId && paramInfo.tenantId.split(',')
  // currentParameterData.value = JSON.parse(paramInfo.content)
  pageInfo.value = paramInfo
}

// 6、判断当前页是否需要调用getCurrentPageInfo
if (['view', 'edit'].includes(pageType)) {
  getCurrentPageInfo()
} else {
  pageInfo.value = {}
}

// const parameterList = ref(null)
// 7、定义被提交的方法
const handleSubmit = async (data) => {
  // const parameter = parameterList.value.getCurrentParameterList()
  // const obj = {}
  // parameter.forEach((item, index) => {
  //   obj[item.param.value] = item.value.value
  // })

  // data.content = JSON.stringify(obj, null, 2)
  let url = 'handleAddCapk'
  const form = { ...data }
  if (pageType === 'edit') {
    url = 'handleUpdateCapk'
    form.id = id
  }
  // form.tenantId = data.tenantId && data.tenantId.join(',')

  handleMessageCommit(`param/${url}`, form).then(() => {
    router.push('/parameter/capk')
  })
}
</script>

<style scoped></style>
